const showMoreCards = {
	init: (cards, button) => {
		if (!button || !cards.length) return;

		let hasHiddenCards = false;
		cards.forEach(card => {
			if (card.classList.contains('display-none')) {
				hasHiddenCards = true;
			}
		});

		if (hasHiddenCards) {
			button.addEventListener('click', e => showMoreCards.displayAdditionalCards(e, button, cards));
		} else {
			showMoreCards.hideShowMoreButton(button);
		}
	},

	displayAdditionalCards: (e, button, cards) => {
		e.preventDefault();
		cards.forEach(card => {
			if (card.classList.contains('display-none')) card.classList.remove('display-none');
		});
		showMoreCards.hideShowMoreButton(button);
	},

	hideShowMoreButton: button => {
		button.classList.add('display-none');
	},
};

document.addEventListener('DOMContentLoaded', function () {
	const showMoreCardSection = document.querySelectorAll('.mod-show-more-cards');

	showMoreCardSection.forEach(section => {
		const cards = section.querySelectorAll('.mod-flex-cards__card');
		const showMorebutton = section.querySelector('.plus-link');

		showMoreCards.init(cards, showMorebutton);
	});
});
